.App {
  text-align: center;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.triangle_container {
  position: relative;
}

.triangle {
  width: 90%;
  max-height: 75vh;
}

.figure_title_container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figure_title {
  font-size: 1rem;
  font-style: italic;
  line-height: 20px;
  text-align: left;
  margin-top: 80px;
  font-weight: lighter;
}

.state_button {
  user-select: none;
  margin: 10px;
  padding: 10px;
  border: 5px solid rgba(0, 0, 0, 0.327) ;
  border-radius: 16px;
  color: #fff;
  background-color: #fff;
  cursor: pointer;
  width: 200px;
  font-size: 2rem;
  transition: transform 0.3s ease;
}

.button_container_top {
  position: absolute;
  top: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
}

.button_container_bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.primary_button {
  background-color: #166534;
}

.secondary_button {
  background-color: #0284c7;
}

.secondary_button:active {
  transform: translate(3px, 3px);
}